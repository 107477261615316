import './NewDealRegistration.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import {createDealRegistration, getEndUser, getProductItem, getReseller} from "src/services/apiServices";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";

function NewDealRegistrationPage() {
    const [productItems, setProductItems] = useState([]);
    const [reseller, setReseller] = useState([]);
    const [endUser, setEndUser] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedReseller, setSelectedReseller] = useState("");
    const [selectedEndUser, setSelectedEndUser] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const getProductList = () => {
        getProductItem().then(res => {
            const data = res.data.data;
            setProductItems(data);
        })
    }

    const getResellerList = () => {
        getReseller().then(res => {
            const data = res.data.data;
            setReseller(data)
        })
    }

    const getEndUserList = () => {
        getEndUser().then(res => {
            const data = res.data.data;
            setEndUser(data)
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        const formData = {
            ItemID: selectedProduct,
            ResellerId: selectedReseller,
            EndUserId: selectedEndUser,
            StartDate: startDate,
            EndDate: endDate,
        };
        createDealRegistration(formData).then(res => {
            toast.success('Deal Registration Sucessfully Created')
        }).catch(err => {
            toast.error(err.response.data.errors[0]);
        })
    };

    useEffect(() => {
        getProductList()
        getResellerList()
        getEndUserList()
    },[])

    return (
        <div className={"new-deal-registration-page"}>
            <main className={"new-deal-registration-content"}>
                <section>
                    <div className={"new-deal-registration-content-header"}>
                        <h1>Deal Registration</h1>
                        <p style={{color:"#737B7D"}}>Streamline your sales process and secure exclusive benefits by registering your deals with us. Start now to maximize your opportunities and gain competitive advantages.</p>
                    </div>
                    <form className={"form-section"}>
                        <div className={"new-deal-registration-container-form"}>
                            <div className={"grid-item"}>
                                <label>Product*</label>
                                <select value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                                    <option value={""}>Select Product</option>
                                    {productItems.map(choices => (
                                        <option key={choices.ID} value={choices.ID}>{choices.Name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={"grid-item"}>
                                <label>Reseller Name*</label>
                                <select value={selectedReseller} onChange={(e) => setSelectedReseller(e.target.value)}>
                                    <option value={""}>Select Reseller</option>
                                    {reseller.map(choices => (
                                        <option key={choices.TABLE_ID}
                                                value={choices.TABLE_ID}>{choices.NAME_1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={"grid-item"}>
                                <label>End User*</label>
                                <select value={selectedEndUser} onChange={(e) => setSelectedEndUser(e.target.value)}>
                                    <option value={""}>Select End User</option>
                                    {endUser.map(choices => (
                                        <option key={choices.TABLE_ID}
                                                value={choices.TABLE_ID}>{choices.NAME_1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={"grid-item"}>
                                <label>Start Date</label>
                                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                            </div>
                            <div className={"grid-item"}>
                                <label>End Date</label>
                                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                    </form>
                    <div style={{textAlign: "center"}}>
                        <button type={"submit"} className={"new-deal-registration-btn"} onClick={handleSubmit}>Submit <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    </div>
                </section>
            </main>
        </div>
    )

}

export default NewDealRegistrationPage