import React, { useEffect, useState } from 'react';
import './DashboardPage.css';
import {getDealRegistration, getTicket} from "src/services/apiServices";
import LoadingOverlay from 'react-loading-overlay-ts';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Tooltip} from "@mui/material";


function DashboardPage() {
    const [dealStatus, setDealStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [ticketList, setTicketList] = useState([]);

    const fetchDealStatus =  () => {
        getDealRegistration(1).then(res => {
            setDealStatus(res.data.data);
            setLoading(false);
        })
    };

    const getTicketList = (page = 1) => {
        getTicket(page).then(res => {
            setTicketList(res.data.results);
            setLoading(false)
        }).catch(err => {
            toast.error(err);
            setLoading(false)
        });
    };

    useEffect(() => {
        setLoading(!loading);
        getTicketList();
        fetchDealStatus();
    }, []);

    const getStatusClass = (status) => {
        switch (status) {
            case 'Open':
                return 'status-open';
            case 'Solved':
                return 'status-solved';
            case 'In Progress':
                return 'status-wip';
            default:
                return '';
        }
    };

    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'Low':
                return 'priority-low';
            case 'Normal':
                return 'priority-normal';
            case 'High':
                return 'priority-high';
            case 'Urgent':
                return 'priority-urgent';
            default:
                return '';
        }
    }

    return (
        <div className="dashboard-page">
            <LoadingOverlay
                active={loading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        position: 'unset',
                        height: '100%',
                    })
                }}
                spinner
            />
            <main className="dashboard-content">
                <section>
                    <h1>Dashboard</h1>
                    <div className="status-section">
                        <div className="ticket-title" onClick={() => navigate('/ticketing')}>
                            <h3>Ticket Status</h3>
                            <p style={{color: '#FD4809', textDecoration: 'underline', fontSize: '16px', cursor:'pointer'}}>See more ticket</p>
                        </div>
                        <div className="status-grid">
                            {ticketList.map((ticket) => (
                                <div key={ticket.id} className={"status-card"}>
                                    <div className={`ticket-status ${getStatusClass(ticket.status)}`}>{ticket.status}</div>
                                    <div className="dashboard-ticket-content">
                                        <p>Customer</p>
                                        <Tooltip title={ticket.customer} arrow>
                                            <p className={'ellipsis'}>{ticket.customer}</p>
                                        </Tooltip>
                                        <p>ID: <b>{ticket.id}</b></p>
                                        <div className={"ticket-priority"}>
                                            <p>Priority:</p>
                                            <div className={`priority ${getPriorityClass(ticket.priority)}`}>
                                                <p>{ticket.priority}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="ticket-title" onClick={() => navigate('/deal-registration')}>
                            <h3>Deal Registration Status</h3>
                            <p style={{color: '#FD4809', textDecoration: 'underline', fontSize: '16px', cursor:'pointer'}}>See more deal registration</p>
                        </div>
                        <div className="deal-grid">
                            {dealStatus.map((deal) => (
                                <div key={deal.id} className={`deal-card`}>
                                    <b>{deal.ORGANIZATION_UNIT}</b>
                                    <hr style={{width: '100%', color: 'rgba(0, 0, 0, 0.04)'}}/>
                                    <p style={{fontStyle: 'italic', fontWeight: '600px', color: 'rgba(0, 0, 0, 0.5)', fontSize: '11px'}}>Reseller</p>
                                    <p>{deal.RESELLER}</p>
                                    <hr style={{width: '100%', color: 'rgba(0, 0, 0, 0.04)'}}/>
                                    <p style={{fontStyle: 'italic', fontWeight: '600px', color: 'rgba(0, 0, 0, 0.5)', fontSize: '11px'}}>Customer</p>
                                    <Tooltip title={deal.END_USER} arrow>
                                        <p className={'ellipsis'} style={{fontWeight: 'bold'}}>{deal.END_USER}</p>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default DashboardPage;
