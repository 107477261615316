import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import {
    faGraduationCap,
    faRightFromBracket,
    faTableColumns,
    faTicket,
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-regular-svg-icons";
import {connect} from "react-redux";


function Sidebar({profilePlaceHolder}) {
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState(null);
    const logout = () => {
        window.localStorage.removeItem('isAuthenticated')
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        navigate('/login')
    }
    const toggleDealMenu = (menuName) => {
        setActiveMenu((prevMenu) => (prevMenu === menuName ? null : menuName));
    };

    return (
        <aside className="sidebar">
            <div className="logo">
                <img src={require('src/assets/logo-tkm.png')} style={{width: '150px', height: '52px'}}/>
            </div>
            <nav className="sidebar-menu">
                <NavLink to="/dashboard"
                         className={({isActive}) => (isActive ? 'menu-item active' : 'menu-item')}><FontAwesomeIcon icon={faTableColumns} /> Dashboard</NavLink>
                <NavLink to="/deal-registration"
                         className={({isActive}) => (isActive ? 'menu-item active' : 'menu-item')}
                         onClick={() => toggleDealMenu('deal-registration')}><FontAwesomeIcon icon={faHandshake} /> Deal
                    Registration</NavLink>
                <div className="menu-item deal-registration">
                    {activeMenu === 'deal-registration' && (
                        <div className="submenu">
                            <NavLink
                                to="/deal-registration/new"
                                className={({isActive}) =>
                                    isActive ? 'submenu-item active' : 'submenu-item'
                                }
                            >
                                New Deal
                            </NavLink>
                        </div>
                    )}
                </div>
                <NavLink to="/ticketing"
                         className={({isActive}) => (isActive ? 'menu-item active' : 'menu-item')}
                         onClick={() => toggleDealMenu('ticketing')}><FontAwesomeIcon icon={faTicket}/> Ticketing</NavLink>
                <div className="menu-item ticketing">
                    {activeMenu === 'ticketing' && (
                        <div className="submenu">
                            <NavLink
                                to="/ticketing/new"
                                className={({isActive}) =>
                                    isActive ? 'submenu-item active' : 'submenu-item'
                                }
                            >
                                New Ticket
                            </NavLink>
                        </div>
                    )}
                </div>
                {/*<Link to="/opportunity" className="menu-item">Opportunity</Link>*/}
                <NavLink to="/university" className="menu-item"><FontAwesomeIcon icon={faGraduationCap}/> TKMT University</NavLink>
                {profilePlaceHolder && profilePlaceHolder.is_staff ? (
                    <NavLink to="/content-management"
                             className={({isActive}) => (isActive ? 'menu-item active' : 'menu-item')}><FontAwesomeIcon icon={faUpload}/> Content
                        Management</NavLink>
                ) : <></>}
            </nav>
            <div className="logout" style={{marginTop: "auto", paddingBottom: "20px"}}>
                <p onClick={logout} style={{cursor: 'pointer'}}><FontAwesomeIcon icon={faRightFromBracket}/> Logout</p>
            </div>
        </aside>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        profilePlaceHolder: state.common.profilePlaceHolder,
    }
}

export default connect(mapStateToProps)(Sidebar);

