import ReactPlayer from "react-player";
import "./ClassPage.css"
import {useEffect, useState} from "react";
import {getCourse} from "src/services/apiServices";
import {useParams} from "react-router-dom";
import {FaChevronRight, FaCircle, FaGgCircle, FaRegCircle, FaRegDotCircle} from "react-icons/fa";

const ClassPage = () => {
    const { classId } = useParams();
    const [activeLesson, setActiveLesson] = useState(0);
    const [course, setCourse] = useState([]);
    const handleLessonClick = (index) => {
        setActiveLesson(index);
    };

    const fetchCourse = (classId) => {
        getCourse(classId).then(res => {
            setCourse(res.data.data)
        })
    }

    useEffect(() => {
        fetchCourse(classId)
    }, [classId])

    return (
        <div className="class-content-page">
            <div className="container-left">
                <div className="class-title">
                    {/*<span>Class #1</span>*/}
                    {course.length > 0 && <h3>{course[0].course_class__title}</h3>}
                </div>
                {course.length > 0 && course[activeLesson] && (
                    <>
                        <ReactPlayer
                            className="class-player"
                            url={`${process.env.REACT_APP_API_URL}/media/${course[activeLesson].video}`}
                            controls
                            width={664}
                            height={411}
                        />
                        <div className="class-description">
                            <span style={{color: '#8F8F8F', fontSize: '20px'}}>Lesson {activeLesson + 1}</span>
                            <h3 style={{
                                fontWeight: 500,
                                lineHeight: '50px',
                                fontSize: '34px'
                            }}>{course[activeLesson].description}</h3>
                        </div>
                    </>
                )}
            </div>
            <div className={"container-right"}>
                <h2>Course Content</h2>
                <ul>
                    {course.map((course, index) => (
                        <li
                            key={index}
                            className={`lesson-item ${activeLesson === index ? 'active' : ''}`}
                            onClick={() => handleLessonClick(index)}
                        >
                            <FaCircle style={{alignSelf: 'center', height: '0.5em'}}/>
                            <div className={'lesson-item-content'}>
                                <span style={{fontWeight: 500, fontSize: '16px'}}>{course.title}</span>
                                <span>{course.duration}</span>
                            </div>
                            <FaChevronRight style={{alignSelf: 'center'}}/>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default ClassPage