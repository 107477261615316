import {FaPlusCircle} from"react-icons/fa"
import "./UploadContentPage.css"
import React, {useEffect, useState} from "react";
import {deleteClass, getClasses} from "src/services/apiServices";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import ModelDeleteConfirm from "src/components/ModalDeleteConfirm/ModelDeleteConfirm";
import LoadingOverlay from "react-loading-overlay-ts";

const UploadContentPage = () => {
    const navigate = useNavigate();
    const [classes, setClasses] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClassId, setSelectedClassId] = useState(null);
    const [loading, setLoading] = useState(true);

    const openUploadForm = () => {
        navigate('/content-management/add')
    }

    const openClassPage = (classId) => {
        navigate(`/content-management/edit/${classId}`)
    }

    const confirmDeleteCourse = (e, index, classId) => {
        e.stopPropagation(); // Prevent triggering the course edit when clicking the X icon
        setSelectedClassId(classId);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        deleteClass(selectedClassId).then(res => {
            toast.success('Class successfully deleted!');
            const updatedCourses = classes.filter(item => item.id !== selectedClassId);
            setClasses(updatedCourses);
            setIsModalOpen(false);
            setSelectedClassId(null);
        }).catch(error => {
            toast.error("Failed to delete class.");
            setIsModalOpen(false);
        });
    };

    useEffect(() => {
        getClasses().then(res => {
            setClasses(res.data.data);
            setLoading(false)
        }).catch(error => {
            toast.error(error.response.data.errors[0]);
        })
    },[])

    return (
        <div className="upload-content-page">
            <LoadingOverlay
                active={loading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        position: 'unset',
                    }),
                    overlay: (base) => ({
                        ...base,
                        height: '150vh',
                    })
                }}
                spinner
            />
            <h1>Content Management</h1>
            <div className={"upload-content"}>
                <div className="add-content-box" onClick={openUploadForm}>
                    <FaPlusCircle/>
                    <p>Add Content</p>
                </div>
                {
                    classes.map((item, index) => (
                        <div className="class-box" onClick={() => openClassPage(item.id)}>
                            <img src={`${process.env.REACT_APP_API_URL}/media/${item.image}`} alt={"No photo"}/>
                            <span className={'span-title'}>{item.title}</span>
                            <span className={'span-description'}>{item.description}</span>
                            <span className="delete-icon"
                                  onClick={(e) => confirmDeleteCourse(e, index, item.id)}>&times;</span>
                        </div>
                    ))
                }
            </div>
            {isModalOpen && (
                <ModelDeleteConfirm
                    isOpen={isModalOpen}
                    type="class"
                    classId={selectedClassId}
                    closeModal={() => setIsModalOpen(false)}
                    onConfirmDelete={handleConfirmDelete}
                />
            )}
        </div>
    )
}

export default UploadContentPage;