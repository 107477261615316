import React, {useEffect, useState} from "react";
import {getClasses} from "src/services/apiServices";
import './UniversityPage.css'
import ClassPage from "src/pages/ClassPage/ClassPage";
import {useNavigate} from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import {toast} from "react-toastify";

const UniversityPage = () => {
    const navigate = useNavigate();
    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState(null);
    const [loading, setLoading] = useState(true);

    const openClassPage = (classId) => {
        navigate(`/university/${classId}`);
    }

    useEffect(() => {
        getClasses().then(res => {
            setClasses(res.data.data);
            setLoading(false);
        }).catch(error => {
            toast.error("Failed to getClasses");
            setLoading(false);
        })
    }, [])

    return (
        <div className="university-content-page">
            <LoadingOverlay
                active={loading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        position: 'unset',
                    }),
                    overlay: (base) => ({
                        ...base,
                        height: '150vh',
                    })
                }}
                spinner
            />
            <h1>TKMT University</h1>
            <div className="university-content">
                {
                    classes.map((item, index) => (
                        <div className="university-class" onClick={() => openClassPage(item.id)}>
                            <img src={`${process.env.REACT_APP_API_URL}/media/${item.image}`} alt={"No photo"}/>
                            <span className={'span-title'}>{item.title}</span>
                            <span className={'span-description'}>{item.description}</span>
                        </div>
                    ))
                }
            </div>
            {classId ?
                <ClassPage classId={classId} />
                : null
            }
        </div>
    )
}

export default UniversityPage;