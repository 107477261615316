import React, { useEffect, useState } from 'react';
import './TicketingPage.css';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Tooltip,
    TextField,
    InputAdornment, TableSortLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {deleteTicket, getTicket} from "src/services/apiServices";
import SearchIcon from "@mui/icons-material/Search";
import ModalTicket from "src/components/ModalTicket/ModalTicket";
import LoadingOverlay from "react-loading-overlay-ts";
import {toast} from "react-toastify";
import ModelDeleteConfirm from "src/components/ModalDeleteConfirm/ModelDeleteConfirm";

function TicketingPage() {
    const [ticketList, setTicketList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5); // Default page size
    const totalPages = Math.ceil(totalCount / pageSize); // Calculate total pages
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [openModal, setOpenModal] = useState(false);
    const [ticketId, setTicketId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [deleteDetect, setDeleteDetect] = useState(true);

    const handleOpenModal = (id) => {
        setOpenModal(true);
        setTicketId(id);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const getTicketList = (page = 1) => {
        setLoading(true)
        getTicket(page).then(res => {
            setTicketList(res.data.results);
            setTotalCount(res.data.count);
            setLoading(false)
        }).catch(err => {
            toast.error(err);
            setLoading(false)
        });
    };

    const deleteSelectedTicket = () => {
        deleteTicket(ticketId).then(res => {
            toast.success(res.data.data)
            setDeleteDetect(false);
        }).catch(err => {
            toast.error(err.response.data.errors[0]);
        })
    }

    useEffect(() => {
        getTicketList(currentPage);
    }, [currentPage, openModal, deleteDetect]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = [...ticketList].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = a[sortConfig.key] || '';
            const bValue = b[sortConfig.key] || '';
            const directionFactor = sortConfig.direction === 'asc' ? 1 : -1;
            return aValue.localeCompare(bValue) * directionFactor;
        }
        return 0;
    });

    const filteredData = sortedData.filter((deal) => {
        return Object.values(deal).some((val) =>
            typeof val === 'string' && val.toLowerCase().includes(searchTerm)
        );
    });

    const getStatusClass = (status) => {
        switch (status) {
            case 'Open':
                return 'status-open';
            case 'Solved':
                return 'status-solved';
            case 'In Progress':
                return 'status-wip';
            default:
                return '';
        }
    };

    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'Low':
                return 'priority-low';
            case 'Normal':
                return 'priority-normal';
            case 'High':
                return 'priority-high';
            case 'Urgent':
                return 'priority-urgent';
            default:
                return '';
        }
    }

    const renderPagination = () => {
        const paginationItems = [];
        const maxVisiblePages = 5; // Maximum number of visible page buttons

        if (totalPages <= maxVisiblePages) {
            // If total pages are less than or equal to maxVisiblePages, show all
            for (let i = 1; i <= totalPages; i++) {
                paginationItems.push(
                    <Button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        variant={currentPage === i ? 'contained' : 'outlined'}
                    >
                        {i}
                    </Button>
                );
            }
        } else {
            // Show first page
            paginationItems.push(
                <Button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    variant={currentPage === 1 ? 'contained' : 'outlined'}
                >
                    1
                </Button>
            );

            // Show ellipsis if needed
            if (currentPage > 3) {
                paginationItems.push(<span key="ellipsis-start">...</span>);
            }

            // Show pages around the current page
            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                paginationItems.push(
                    <Button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        variant={currentPage === i ? 'contained' : 'outlined'}
                    >
                        {i}
                    </Button>
                );
            }

            // Show ellipsis if needed
            if (currentPage < totalPages - 2) {
                paginationItems.push(<span key="ellipsis-end">...</span>);
            }

            // Show last page
            paginationItems.push(
                <Button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    variant={currentPage === totalPages ? 'contained' : 'outlined'}
                >
                    {totalPages}
                </Button>
            );
        }

        return paginationItems;
    };

    const confirmDeleteTicket = (e, id) => {
        e.stopPropagation();
        setTicketId(id);
        setIsModalDeleteOpen(true);
    };

    return (
        <div className={"ticket-content"}>
            <LoadingOverlay
                active={loading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        position: 'unset',
                    }),
                    overlay: (base) => ({
                        ...base,
                        height: '150vh',
                    })
                }}
                spinner
            />
            <div className={"deal-registration-content-header"}>
                <h1>Ticketing</h1>
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{
                        marginBottom: '16px',
                        height: '38px',
                        '& .MuiInputBase-root': {height: '38px', backgroundColor: 'white', borderWidth: 0}
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/> {/* This adds the magnifying glass icon */}
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{backgroundColor: '#F9F8F9'}}>
                                {['customer', 'entitlement', 'category', 'status', 'origin', 'priority', 'description', 'assignee', 'response_date', 'resolution_date'].map((header) => (
                                    <TableCell key={header}>
                                        <TableSortLabel
                                            active={sortConfig.key === header}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort(header)}
                                        >
                                            {header
                                                .replace(/_/g, ' ') // Replace underscores with spaces
                                                .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
                                                .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
                                                .trim() // Remove any leading/trailing spaces
                                            }
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell className="table-cell-ellipsis">
                                        <Tooltip title={row.customer} arrow>
                                            <span>{row.customer}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <div
                                            className={`entitlement-chip ${row.entitlement}`}>{row.entitlement}</div>
                                    </TableCell>
                                    <TableCell className="table-cell-ellipsis">
                                    <Tooltip title={row.category} arrow>
                                            <span>{row.category}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <div
                                            className={`status-chip ${getStatusClass(row.status)}`}>{row.status}
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.origin}</TableCell>
                                    <TableCell>
                                        <div
                                            className={`priority-chip ${getPriorityClass(row.priority)}`}>{row.priority}
                                        </div>
                                    </TableCell>
                                    <TableCell className="table-cell-ellipsis">
                                        <Tooltip title={row.description} arrow>
                                        <span>{row.description}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{row.assignee}</TableCell>
                                    <TableCell>{row.response_date}</TableCell>
                                    <TableCell>{row.resolution_date}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" aria-label="view" onClick={() => handleOpenModal(row.id)}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                        <IconButton color="error" aria-label="delete" onClick={(e) => confirmDeleteTicket(e, row.id)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="pagination-controls">
                    <Button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</Button>
                    {renderPagination()}
                    <Button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</Button>
                </div>
            <ModalTicket
                id={ticketId}
                isOpen={openModal}
                closeModal={handleCloseModal}
            />
            {isModalDeleteOpen && (
                <ModelDeleteConfirm
                    isOpen={isModalDeleteOpen}
                    type="ticket"
                    closeModal={() => setIsModalDeleteOpen(false)}
                    onConfirmDelete={deleteSelectedTicket}
                />
            )}
            </div>
    );
}

export default TicketingPage;
