import './NewTicketPage.css'
import {createTicket, getEndUser, getReseller, getTicketCategory} from "src/services/apiServices";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {connect} from "react-redux";

function NewTicketPage ({profilePlaceHolder}){
    const [customer, setCustomer] = useState(null);
    const [priority, setPriority] = useState(null);
    const [ticketCategory, setTicketCategory] = useState(null);
    const [entitlement, setEntitlement] = useState(null);
    const [status, setStatus] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [description, setDescription] = useState('');
    const [responseDate, setResponseDate] = useState(null);
    const [resolutionDate, setResolutionDate] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [ticketCategoryList, setTicketCategoryList] = useState([]);
    const [resellerList, setResellerList] = useState([]);

    const entitlementList = ['SLA', 'Non SLA']
    const statusList = ['Open', 'Solved', 'In Progress'];
    const priorityList = ['Low', 'Normal', 'High', 'Urgent'];
    const getCustomerList = () => {
        getEndUser().then((response) => {
            setCustomerList(response.data.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    const getTicketList = () => {
        getTicketCategory().then(res => {
            setTicketCategoryList(res.data.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    const getResellerList = () => {
        getReseller().then(res => {
            setResellerList(res.data.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            "customer": customer,
            "entitlement": entitlement,
            "category": ticketCategory,
            "status": status,
            "priority": priority,
            "origin": origin,
            "description": description,
            "assignee": assignee,
            "response_date": responseDate,
            "resolution_date": resolutionDate,
        }
        createTicket(data).then(res => {
            toast.success(res.data.data)
        }).catch(e => {
            toast.error(e.response.data.errors[0]);
        })
    }

    useEffect(() => {
        getCustomerList()
        getTicketList()
        getResellerList()
    },[])

    return(
        <div className="new-ticket-page">
            <main className="new-ticket-content">
                <section>
                    <div className={"new-ticket-content-header"}>
                        <h1>Ticketing</h1>
                    </div>
                    <form className={"form-section"}>
                        <div className={"new-ticket-container-form"}>
                            <div className={"grid-item"}>
                                <label>Customer*</label>
                                <select value={customer} onChange={(e) => setCustomer(e.target.value)}>
                                    <option value={""}>Select Customer</option>
                                    {customerList.map(choices => (
                                        <option key={choices.TABLE_ID}
                                                value={choices.NAME_1}>{choices.NAME_1}</option>
                                    ))}
                                </select>
                            </div>
                            {profilePlaceHolder && profilePlaceHolder.is_staff ? (
                                <div className={"grid-item"}>
                                    <label>Entitlement*</label>
                                    <select value={entitlement} onChange={(e) => setEntitlement(e.target.value)}>
                                        <option value={""}>Select Entitlement</option>
                                        {entitlementList.map(choices => (
                                            <option key={choices}
                                                    value={choices}>{choices}</option>
                                        ))}
                                    </select>
                                </div>
                            ):<></>}
                            <div className={"grid-item"}>
                                <label>Category-SubCategory*</label>
                                <select value={ticketCategory} onChange={(e) => setTicketCategory(e.target.value)}>
                                    <option value={""}>Select Category</option>
                                    {ticketCategoryList.map(choices => (
                                        <option key={choices.ID}
                                                value={choices.Name}>{choices.Name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={"grid-item"}>
                                <label>Status*</label>
                                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value={""}>Select Status</option>
                                    {statusList.map(choices => (
                                        <option key={choices}
                                                value={choices}>{choices}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={"grid-item"}>
                                <label>Priority*</label>
                                <select value={priority} onChange={(e) => setPriority(e.target.value)}>
                                    <option value={""}>Select Priority</option>
                                    {priorityList.map(choices => (
                                        <option key={choices}
                                                value={choices}>{choices}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={"grid-item"}>
                                <label>Origin*</label>
                                <select value={origin} onChange={(e) => setOrigin(e.target.value)}>
                                    <option value={""}>Select Origin</option>
                                    <option value={origin}>Portal</option>
                                </select>
                            </div>
                            {profilePlaceHolder && profilePlaceHolder.is_staff ? (
                                <>
                                    <div className={"grid-item"}>
                                        <label>Assign To*</label>
                                        <select value={assignee} onChange={(e) => setAssignee(e.target.value)}>
                                            <option value={""}>Select Assignee</option>
                                            {resellerList.map(choices => (
                                                <option key={choices.TABLE_ID}
                                                        value={choices.NAME_1}>{choices.NAME_1}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={"grid-item"}>
                                        <label>Response Date</label>
                                        <input type="date" value={responseDate}
                                               onChange={(e) => setResponseDate(e.target.value)}/>
                                    </div>
                                    <div className={"grid-item"}>
                                        <label>Resolution Date</label>
                                        <input type="date" value={resolutionDate}
                                               onChange={(e) => setResolutionDate(e.target.value)}/>
                                    </div>
                                </>
                            ) : <></>}
                            <div className={"grid-item"}>
                                <label>Description*</label>
                                <textarea
                                    rows={6}
                                    cols={50}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                    <div style={{textAlign: "center"}}>
                        <button type={"submit"} className={"new-deal-registration-btn"} onClick={handleSubmit}
                        >Submit <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                    </div>
                </section>
            </main>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        profilePlaceHolder: state.common.profilePlaceHolder,
    }
}

export default connect(mapStateToProps)(NewTicketPage);