import './ModalTicket.css'
import React, {useEffect, useState} from "react";
import {getEndUser, getReseller, getTicketCategory, getTicketDetails, updateTicket} from "src/services/apiServices";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";

const ModalTicket = ({isOpen, closeModal, id}) => {
    const [customer, setCustomer] = useState(null);
    const [priority, setPriority] = useState(null);
    const [ticketCategory, setTicketCategory] = useState(null);
    const [entitlement, setEntitlement] = useState(null);
    const [status, setStatus] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [description, setDescription] = useState('');
    const [responseDate, setResponseDate] = useState(null);
    const [resolutionDate, setResolutionDate] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [ticketCategoryList, setTicketCategoryList] = useState([]);
    const [resellerList, setResellerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const entitlementList = ['SLA', 'Non SLA']
    const statusList = ['Open', 'Solved', 'In Progress'];
    const priorityList = ['Low', 'Normal', 'High', 'Urgent'];
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleSubmit = () => {
        setLoading(true)
        const data = {
            "customer": customer,
            "entitlement": entitlement,
            "category": ticketCategory,
            "status": status,
            "priority": priority,
            "origin": origin,
            "description": description,
            "assignee": assignee,
            "response_date": responseDate,
            "resolution_date": resolutionDate,
        }

        updateTicket(id, data).then(res => {
            toast.success(res.data.data)
            setLoading(false)
            closeModal()
        }).catch(e => {
            toast.error(e.response.data.errors[0]);
            setLoading(false)
        })
    }

    useEffect(() => {
        if(!isOpen) return;
        setLoading(true);
        getTicketDetails(id).then((response) => {
            const data = response.data.data;
            if (data) {
                setCustomer(data.customer || "");
                setPriority(data.priority || "");
                setTicketCategory(data.category || "");
                setEntitlement(data.entitlement || "");
                setStatus(data.status || "");
                setOrigin(data.origin || "");
                setDescription(data.description || "");
                setResponseDate(data.response_date || null);
                setResolutionDate(data.resolution_date || null);
                setAssignee(data.assignee || "");
            } else {
                toast.error("No data found for the given ID");
            }
        }).catch((error) => {
            toast.error("Error fetching ticket details:", error);
        });
        getEndUser().then((response) => {
            setCustomerList(response.data.data);
        }).catch((error) => {
            toast.error(error.response.data.errors[0]);
        })
        getTicketCategory().then(res => {
            setTicketCategoryList(res.data.data);
        }).catch((error) => {
            toast.error(error.response.data.errors[0]);
        })
        getReseller().then(res => {
            setResellerList(res.data.data);
        }).catch((error) => {
            toast.error(error.response.data.errors[0]);
        })
        setLoading(false)
    }, [id])

    return (
        <div className={`modal-overlay ${isOpen ? "open" : ""}`} onClick={closeModal}>
            <LoadingOverlay
                active={loading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        position: 'unset',
                        height: '100%',
                    })
                }}
                spinner
            />
            <div className={`modal-content ${isOpen ? "show" : ""}`} onClick={stopPropagation}>
                <div className="form-section">
                    <div className={"new-ticket-container-form"}>
                        <div className={"grid-item"}>
                            <label>Customer*</label>
                            <select value={customer} onChange={(e) => setCustomer(e.target.value)}>
                                <option value={""}>Select Customer</option>
                                {customerList.map(choices => (
                                    <option key={choices.TABLE_ID}
                                            value={choices.NAME_1}>{choices.NAME_1}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"grid-item"}>
                            <label>Entitlement*</label>
                            <select value={entitlement} onChange={(e) => setEntitlement(e.target.value)}>
                                <option value={""}>Select Entitlement</option>
                                {entitlementList.map(choices => (
                                    <option key={choices}
                                            value={choices}>{choices}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"grid-item"}>
                            <label>Category-SubCategory*</label>
                            <select value={ticketCategory} onChange={(e) => setTicketCategory(e.target.value)}>
                                <option value={""}>Select Category</option>
                                {ticketCategoryList.map(choices => (
                                    <option key={choices.ID}
                                            value={choices.Name}>{choices.Name}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"grid-item"}>
                            <label>Status*</label>
                            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value={""}>Select Status</option>
                                {statusList.map(choices => (
                                    <option key={choices}
                                            value={choices}>{choices}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"grid-item"}>
                            <label>Priority*</label>
                            <select value={priority} onChange={(e) => setPriority(e.target.value)}>
                                <option value={""}>Select Priority</option>
                                {priorityList.map(choices => (
                                    <option key={choices}
                                            value={choices}>{choices}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"grid-item"}>
                            <label>Origin*</label>
                            <select value={origin} onChange={(e) => setOrigin(e.target.value)}>
                                <option value={""}>Select Origin</option>
                                <option value={"Portal"}>Portal</option>
                            </select>
                        </div>
                        <div className={"grid-item"}>
                            <label>Assign To*</label>
                            <select value={assignee} onChange={(e) => setAssignee(e.target.value)}>
                                <option value={""}>Select Assignee</option>
                                {resellerList.map(choices => (
                                    <option key={choices.TABLE_ID}
                                            value={choices.NAME_1}>{choices.NAME_1}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"grid-item"}>
                            <label>Response Date</label>
                            <input type="date" value={responseDate} onChange={(e) => setResponseDate(e.target.value)}/>
                        </div>
                        <div className={"grid-item"}>
                            <label>Resolution Date</label>
                            <input type="date" value={resolutionDate}
                                   onChange={(e) => setResolutionDate(e.target.value)}/>
                        </div>
                        <div className={"grid-item"}>
                            <label>Description*</label>
                            <textarea
                                rows={6}
                                cols={50}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <button type={"submit"} className={"new-deal-registration-btn"} onClick={handleSubmit}
                    >Update <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalTicket