import axios from "axios";

const tkmInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        "Accept": "application/json",
    }, transformRequest: [(data, headers) => {
        if(data){
            if(data.data)
                return {...data.data};
            return data;
        }
        return data;
    }, ...axios.defaults.transformRequest]
})

const tkmInstanceWithoutHeader = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    transformRequest: [(data, headers) => {
        if(data){
            if(data.data)
                return {...data.data}
            else
                return data
        }

        return data
    }, ...axios.defaults.transformRequest]
})

tkmInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        if(error.response.status === 401 && originalRequest.url === '/auth/token/refresh'){
            window.location.href = '/login';
            return Promise.reject(error);
        }
        if(error.response.status === 401 && !originalRequest._retry){
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            return tkmInstanceWithoutHeader.post('/auth/token/refresh', {refresh: refreshToken}).then((response) => {
                    localStorage.setItem('accessToken', response.data.access);
                    tkmInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                    originalRequest.headers['Authorization'] = "Bearer " + response.data.access;
                    return tkmInstance(originalRequest);
                }
            ).catch(error => {
                window.location.href = '/login';
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('isAuthenticated');
            })
        }
        return Promise.reject(error);
    }
)

export const obtainToken = (data) => {
    return tkmInstanceWithoutHeader.post('auth/login', data);
}

export const register = (data) => {
    return tkmInstanceWithoutHeader.post('auth/register', data);
}

export const addClass = (data) => {
    return tkmInstance.post('/api/exam/classes', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const editClass = (data, classId) => {
    return tkmInstance.put(`/api/exam/classes/${classId}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export const deleteClass = (classId) => {
    return tkmInstance.delete(`/api/exam/classes/${classId}`, {})
}

export const addCourses = (data, classId) => {
    return tkmInstance.post(`/api/exam/classes/${classId}/courses`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const deleteCourse = (courseId) => {
    return tkmInstance.delete(`/api/exam/classes/courses/${courseId}`)
}

export const getClasses = () => {
    return tkmInstance.get(`/api/exam/classes`);
}

export const getClassById = (id) => {
    return tkmInstance.get(`/api/exam/classes/${id}`);
}

export const getCourse = (class_id) => {
    return tkmInstance.get(`/api/exam/classes/${class_id}/courses`);
}

export const updateProfile = (data) => {
    return tkmInstance.put('auth/user/update', data)
}

export const getProfile = () => {
    return tkmInstance.get('auth/user/profile');
}

export const uploadProfilePhoto = (data) => {
    return tkmInstance.put('auth/user/upload-photo', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}

export const changePassword = (data) => {
    return tkmInstance.post('auth/user/change-password', data, {})
}

export const getDealRegistration = (page) => {
    return tkmInstance.get('api/genesis/deal-registration?page=' + page);
}

export const createDealRegistration = (data) => {
    return tkmInstance.post('api/genesis/deal-registration', data, {})
}

export const getProductItem = () => {
    return tkmInstance.get('api/genesis/item-product');
}

export const getReseller = () => {
    return tkmInstance.get('api/genesis/reseller');
}

export const getEndUser = () => {
    return tkmInstance.get('api/genesis/end-user');
}

export const getTicketCategory = () => {
    return tkmInstance.get('api/genesis/ticket-category');
}

export const getTicket = (page = 1) => {
    return tkmInstance.get('api/genesis/ticket?page=' + page);
}

export const createTicket = (data) => {
    return tkmInstance.post('api/genesis/ticket', data, {})
}

export const getTicketDetails = (id) => {
    return tkmInstance.get('api/genesis/ticket/' + id);
}

export const updateTicket = (id, data) => {
    return tkmInstance.put(`/api/genesis/ticket/${id}`, data, {})
}

export const deleteTicket = (id) => {
    return tkmInstance.delete(`/api/genesis/ticket/${id}`);
}