import {useEffect, useRef, useState} from "react";
import "./UploadContentForm.css";
import TextInput from "src/components/TextInput/TextInput";
import ModalCourse from "src/components/ModalCourse/ModalCourse";
import {addClass, addCourses, deleteCourse, editClass, getClassById, getCourse} from "src/services/apiServices";
import {useParams} from "react-router-dom";
import {FaPlusCircle} from "react-icons/fa";
import {toast} from "react-toastify";
import ProgressBar from "src/components/ProgressBar/ProgressBar";

const UploadContentForm = () => {
    const { classId } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [imageClass, setImageClass] = useState(null);
    const [imageClassBlob, setImageClassBlob] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCourse, setCurrentCourse] = useState(null);
    const [courses, setCourses] = useState([]);
    const [isClassSet, setIsClassSet] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const addCourse = (titleCourse, descriptionCourse, videoCourse, duration, thumbnail) => {
        const course = {
            title: titleCourse,
            description: descriptionCourse,
            duration: duration,
            order: courses && courses.length > 0 ? courses.length + 1 : 1,
            thumbnail: thumbnail
        };
        setCourses(prevCourses => [...prevCourses, course]);
        setIsModalOpen(false); // Optional: Close modal after adding a course

        const chunkSize = 1024 * 1024; // 1MB
        const totalChunks = Math.ceil(videoCourse.size / chunkSize);
        let totalUploaded = 0; // Track total uploaded bytes
        setIsUploading(true);

        const uploadChunk = (chunkIndex) => {
            if (chunkIndex >= totalChunks) {
                toast.success('Course successfully added!');
                setIsUploading(false);
                return;
            }

            const start = chunkIndex * chunkSize;
            const end = Math.min(start + chunkSize, videoCourse.size);
            const chunk = videoCourse.slice(start, end);

            const form = new FormData();
            form.append("title", course.title);
            form.append("description", course.description);
            form.append("video", chunk, `${titleCourse}-video-chunk-${chunkIndex}`);
            form.append("duration", course.duration);
            form.append("order", course.order);
            form.append("thumbnail", thumbnail, `${titleCourse}-thumbnail.png`);
            form.append("chunkIndex", chunkIndex);
            form.append("totalChunks", totalChunks);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${process.env.REACT_APP_API_URL}/api/exam/classes/${classId}/courses`, true);
            const token = localStorage.getItem('accessToken');
            if (token) {
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    // Update total uploaded bytes after successful upload
                    totalUploaded += chunk.size; // Add the size of the uploaded chunk
                    // Calculate overall progress based on total video size
                    const overallProgress = Math.min((totalUploaded / videoCourse.size) * 100, 100);
                    setUploadProgress(overallProgress); // Update the progress bar
                    // Proceed to upload the next chunk
                    uploadChunk(chunkIndex + 1);
                } else {
                    toast.error('Failed to add course');
                    setIsUploading(false);
                }
            };

            xhr.onerror = () => {
                console.error('Error during upload:', xhr.statusText);
                toast.error('Error during upload');
                setIsUploading(false);
            };

            xhr.send(form);
        };

        // Start uploading the first chunk
        uploadChunk(0);
    };


    const addNewClass = (e) => {
        e.preventDefault()
        const form = new FormData()
        form.append("title", title)
        form.append("description", description)
        form.append("image", imageClassBlob)
        addClass(form).then(res => {
            setIsClassSet(true);
            toast.success('Class successfully added!');
        })
    }

    const editCurrentClass = (e) => {
        e.preventDefault();
        if (!classId) return;

        const form = new FormData();
        form.append("title", title);
        form.append("description", description);

        if (imageClassBlob) {
            form.append("image", imageClassBlob);
        }

        editClass(form, classId)
            .then((res) => {
                toast.success('Class successfully updated!');
            })
            .catch((err) => {
                console.error(err);
                toast.error('Failed to update class');
            });
    }

    const handleDeleteCourse = (e, index, courseId) => {
        e.stopPropagation();  // Prevent triggering the course edit when clicking the X icon

        const updatedCourses = courses.filter((_, i) => i !== index);
        setCourses(updatedCourses);

        deleteCourse(courseId).then(res => {
            toast.success('Course successfully deleted!');
        })
    };


    const handleSubmit = (e) => {
        if (isClassSet) {
            editCurrentClass(e);
        } else {
            addNewClass(e);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentCourse(null);
    }

    const openModalForNewCourse = () => {
        setCurrentCourse(null);
        setIsModalOpen(true);
    };

    const openModalForEditCourse = (course) => {
        setCurrentCourse(course);
        setIsModalOpen(true);
    };

    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setImageClass(URL.createObjectURL(file));
        setImageClassBlob(file)
    };

    useEffect(() => {
        if(classId){
            setIsClassSet(true);
            getCourse(classId).then(res => setCourses(res.data.data));
            getClassById(classId).then(res => {
                setTitle(res.data.data.title)
                setDescription(res.data.data.description)
                setImageClass(`${process.env.REACT_APP_API_URL}${res.data.data.image}`);
            });
        }
    },[classId])

    return (
        <div className="upload-content-container">
            <h3>Class Description</h3>
            <div className={"upload-content-form"}>
                <TextInput label="Title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                <TextInput label="Description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                <img src={imageClass} alt={"No photo available"} onClick={handleImageClick}/>
                <span>Click image to change</span>
                <input
                    type="file"
                    style={{display: "none"}}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </div>
            <button className="btn-submit-class" onClick={handleSubmit}>{isClassSet ? 'Update Class' : 'Save Class'}</button>
            <h3>Courses</h3>
            {
                <div className={"loop-course-content"}>
                    <div className="add-content-box" onClick={() => openModalForNewCourse()}>
                        <FaPlusCircle/>
                        <p>Add Course</p>
                    </div>
                    { courses && courses.length > 0 ? (
                        courses.map((item, index) => (
                            <div className={"course-content-upload"} onClick={() => openModalForEditCourse(item)}>
                                <img src={`${process.env.REACT_APP_API_URL}/media/${item.thumbnail}`} alt={"Course Thumbnail"}/>
                                <span className={'span-title'}>{item.title}</span>
                                <span className={'span-description'}>{item.description}</span>
                                <span className="delete-icon" onClick={(e) => handleDeleteCourse(e, index, item.id)}>&times;</span>
                            </div>
                        ))) : null
                    }
                </div>
            }
            <ModalCourse
                isOpen={isModalOpen}
                closeModal={closeModal}
                addCourse={addCourse}
                mode={currentCourse ? "update" : "create"}
                initialTitle={currentCourse?.title || ""}
                initialDescription={currentCourse?.description || ""}
                initialVideo={null}
            />
            {isUploading && <ProgressBar progress={uploadProgress} />}
        </div>
    );
};

export default UploadContentForm;
