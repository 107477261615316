import {SET_PROFILE} from "src/store/common/action";

const setProfile = (state, action) => {
    return {
        ...state,
        profilePlaceHolder: action.payload
    }
}

export default (state, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return setProfile(state, action);
        default:
            return {
                profilePlaceHolder: {
                    "first_name": '',
                    "last_name": '',
                    "email": '',
                    "phone_number": '',
                    "profile_photo": '',
                    "is_staff": ''
                },
            }
    }
}
