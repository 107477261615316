const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);  // Get full hours
    const minutes = Math.floor((duration % 3600) / 60);  // Get remaining minutes
    const seconds = Math.floor(duration % 60);  // Get remaining seconds

    let formattedDuration = '';

    if (hours > 0) {
        formattedDuration += `${hours} hour${hours !== 1 ? 's' : ''} `;
    }
    formattedDuration += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
    formattedDuration += `${seconds} second${seconds !== 1 ? 's' : ''}`;

    return formattedDuration;
};


export { formatDuration };