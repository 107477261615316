import React, {useEffect, useLayoutEffect, useState} from "react";
import './DealRegistrationPage.css'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, TableSortLabel, InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {getDealRegistration} from "src/services/apiServices";
import LoadingOverlay from 'react-loading-overlay-ts';


function DealRegistrationPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [dealData, setDealData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = [...dealData].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = a[sortConfig.key] || '';  // Set to empty string if undefined
            const bValue = b[sortConfig.key] || '';  // Set to empty string if undefined
            const directionFactor = sortConfig.direction === 'asc' ? 1 : -1;
            return aValue.localeCompare(bValue) * directionFactor;
        }
        return 0;
    });

    const filteredData = sortedData.filter((deal) => {
        return Object.values(deal).some((val) =>
            typeof val === 'string' && val.toLowerCase().includes(searchTerm)
        );
    });

    const fetchDealStatus =  () => {
        setLoading(!loading);
        getDealRegistration(page).then(res => {
            setDealData(prevItems => [...prevItems, ...res.data.data]);
            setLoading(false);
        }).catch((err) => {
            setLoading(false)
        })
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    }

    useEffect(() => {
        fetchDealStatus()
    }, [page])

    useLayoutEffect(() => {
        if (loading) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
        }
        if (!loading) {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
        }
    }, [loading]);

    return (
        <div className={"deal-registration-page"}>
            <LoadingOverlay
                active={loading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        position: 'unset',
                    }),
                    overlay: (base) => ({
                        ...base,
                        height: '150vh',
                    })
                }}
                spinner
            />
            <main className={"deal-registration-content"}>
                <section>
                    <div className={"deal-registration-content-header"}>
                        <h1>Deal Registration</h1>
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{
                                marginBottom: '16px',
                                height: '38px',
                                '& .MuiInputBase-root': {height: '38px', backgroundColor: 'white', borderWidth: 0}
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/> {/* This adds the magnifying glass icon */}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{backgroundColor: '#F9F8F9'}}>
                                    {['product', 'resellerName', 'customerName', 'startDate', 'endDate'].map((header) => (
                                        <TableCell key={header}>
                                            <TableSortLabel
                                                active={sortConfig.key === header}
                                                direction={sortConfig.direction}
                                                onClick={() => handleSort(header)}
                                            >
                                                {header.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.map((deal, index) => (
                                    <TableRow key={index}>
                                        <TableCell>SIEM - TKM</TableCell>
                                        <TableCell>{deal.RESELLER}</TableCell>
                                        <TableCell>{deal.END_USER}</TableCell>
                                        <TableCell>{deal.START_DATE.split("T")[0]}</TableCell>
                                        <TableCell>{deal.END_DATE.split("T")[0]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </section>
                <button className={'btn-load-more'} onClick={handleLoadMore}>Load More</button>
            </main>
        </div>
    )
}

export default DealRegistrationPage;