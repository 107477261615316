import TextInput from "src/components/TextInput/TextInput";
import {useEffect, useState} from "react";
import "./ModalCourse.css"
import {formatDuration} from "src/services/utils";

const ModalCourse = ({
                         isOpen,
                         closeModal,
                         addCourse,
                         updateCourse,
                         mode = "create", // "create" or "update"
                         initialTitle = "",
                         initialDescription = "",
                         initialVideo = null,
                     }) => {
    const [titleCourse, setTitleCourse] = useState('');
    const [descriptionCourse, setDescriptionCourse] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [duration, setDuration] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [thumbnailBlob, setThumbnailBlob] = useState(null);

    useEffect(() => {
        if (mode === "update") {
            setTitleCourse(initialTitle);
            setDescriptionCourse(initialDescription);
            setSelectedFile(initialVideo);
        } else if (mode === "create") {
            // Reset fields for a new course
            setTitleCourse('');
            setDescriptionCourse('');
            setSelectedFile(null);
            setDuration(null);
        }
    }, [isOpen, mode, initialTitle, initialDescription, initialVideo]);

    const handleSaveCourse = () => {
        if (mode === "create") {
            addCourse(titleCourse, descriptionCourse, selectedFile, duration, thumbnailBlob);
        } else if (mode === "update") {
            updateCourse(titleCourse, descriptionCourse, selectedFile, duration);
        }
        resetForm(); // Reset after saving
    };

    const resetForm = () => {
        setTitleCourse('');
        setDescriptionCourse('');
        setSelectedFile(null);
        setDuration(null);
        setThumbnail(null);
    };

    // Handler to call addCourse with titleCourse and descriptionCourse
    // const handleAddCourse = () => {
    //     addCourse(titleCourse, descriptionCourse, selectedFile, duration);
    //     setTitleCourse(''); // Optional: Reset titleCourse after adding
    //     setDescriptionCourse('');
    //     setSelectedFile(null)// Optional: Reset descriptionCourse after adding
    //     setDuration(null);
    // };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const dataURLtoBlob = (dataURL) => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const video = document.createElement('video');
            const videoURL = URL.createObjectURL(file);

            video.src = videoURL;
            video.onloadedmetadata = () => {
                setDuration(formatDuration(video.duration));
                video.currentTime = video.duration / 2;
            }
            video.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, canvas.width, canvas.height);

                const thumbnailURL = canvas.toDataURL('image/png');
                const thumbnailBlob = dataURLtoBlob(thumbnailURL);
                console.log(thumbnailBlob)
                setThumbnailBlob(thumbnailBlob);
                setThumbnail(thumbnailURL);
                URL.revokeObjectURL(videoURL);
            };
        }
        setSelectedFile(file);
    }

    return (
        <div className={`modal-overlay ${isOpen ? "open" : ""}`} onClick={closeModal}>
            <div className={`modal-content ${isOpen ? "show" : ""}`} onClick={stopPropagation}>
                <div className="modal-form">
                    <TextInput label="Title" value={titleCourse} onChange={(e) => setTitleCourse(e.target.value)}/>
                    <label>Description</label>
                    <textarea
                        value={descriptionCourse}
                        onChange={(e) => setDescriptionCourse(e.target.value)}
                    />
                    <label>Video</label>
                    <input type="file" onChange={handleFileChange}/>
                </div>
                <button className="btn-submit-course" onClick={handleSaveCourse}>
                    {mode === "create" ? "Add Course" : "Update Course"}
                </button>
            </div>
        </div>
    );
};

export default ModalCourse;
