import React, {useEffect, useState} from 'react';
import './LoginPage.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';
import {obtainToken, register} from "src/services/apiServices";
import LoadingOverlay from 'react-loading-overlay-ts';
import {toast} from "react-toastify";


function LoginPage() {
    const [isSignUp, setIsSignUp] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleToggle = () => {
        setIsSignUp(!isSignUp);
    };

    const handleSignUp = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        let data = {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
        }
        register(data)
            .then(res => {
                console.log('check email')
            }).catch(err => {
                console.log(err);
        })
        // Perform sign-up logic here
        console.log('Sign-up successful');
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(!loading);
        const data = {
            username: email,
            password: password,
        }

        try {
            const res = await obtainToken(data);  // Wait for the token to be obtained
            localStorage.setItem('accessToken', res.data.access);
            localStorage.setItem('refreshToken', res.data.refresh);
            localStorage.setItem('isAuthenticated', 'true');
            setIsAuthenticated(true);
            navigate('/dashboard');
        } catch (error) {
            setLoading(false);
            toast.error(error.response.statusText)
        }
    };

    useEffect(() => {
        // if (isAuthenticated) {
        //     navigate('/dashboard');
        // }
        if (isSignUp) {
            // Check if all sign-up fields are filled and passwords match
            if (email !== '' && password !== '' && confirmPassword !== '') {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        } else {
            // Check if both email and password are filled for login
            if (email !== '' && password !== '') {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        }
    }, [email, password, confirmPassword, isSignUp, isAuthenticated, navigate]);

    return (
        <div className="auth-page">
            <LoadingOverlay
                active={loading}
                styles={{
                    wrapper: (base) => ({
                        ...base,
                        position: 'unset'
                    })
                }}
                spinner
            />
            <div className="auth-left">
                <h1>TRI KREASI MANDIRI TEKNOLOGI</h1>
                <p>We are an IT Solution company who provide our customers with hardware and software needs.</p>
            </div>
            <div className="auth-right">
                <a href="/" className="back-to-home">← Back to Home</a>
                <div className="auth-form-container">
                    <div className="toggle-buttons">
                        <button
                            className={isSignUp ? 'active' : ''}
                            onClick={handleToggle}
                        >
                            Sign Up
                        </button>
                        <button
                            className={!isSignUp ? 'active' : ''}
                            onClick={handleToggle}
                        >
                            Login
                        </button>
                    </div>
                    {isSignUp ? (
                        <form className="auth-form" onSubmit={handleSignUp}>
                            <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                            <input type={"text"} placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
                            <input type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <input type="password" placeholder="Ketik ulang password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                            {error && <p className="error">{error}</p>}
                            <button type="submit" className={isFormValid ? 'submit-btn active' : 'submit-btn'} disabled={!isFormValid}>Sign Up</button>
                        </form>
                    ) : (
                        <form className="auth-form" onSubmit={handleLogin}>
                            <input type="text" placeholder="Email Address" value={email} onChange={(e)=> setEmail(e.target.value)} required />
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <button type="submit" className={isFormValid ? 'submit-btn active' : 'submit-btn'} disabled={!isFormValid}>Login</button>
                        </form>
                    )}
                    <a href="/forgot-password" className="forgot-password">Forgot Password</a>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
