import React, {useEffect} from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import {connect, useSelector} from "react-redux";
import {getProfile} from "src/services/apiServices";
import {SET_PROFILE} from "src/store/common/action";


function Header({setProfile}) {
    const navigate = useNavigate();
    const profilePlaceHolder = useSelector((state) => state.common.profilePlaceHolder);

    useEffect(() => {
        getProfile().then(response => {
            const data = response.data.data;
            setProfile(data)
        })
    }, [])

    return (
        <header className={'header'}>
            <div className="welcome">
                <h3>Welcome, {profilePlaceHolder.first_name + ' ' + profilePlaceHolder.last_name}</h3>
                <p>Have a good day!</p>
            </div>
            <img
                src={`${process.env.REACT_APP_API_URL}${profilePlaceHolder.profile_photo}`} // Replace with the actual image source
                alt="User Avatar"
                className="avatar"
                onClick={() => navigate('/profile')}
                style={{cursor: 'pointer', objectFit: 'cover', width: '40px', height: '40px', borderRadius: '100px'}}
            />
        </header>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProfile: payload => dispatch({type: SET_PROFILE, payload: payload})
    }
}

export default connect(null, mapDispatchToProps)(Header);