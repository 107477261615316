import React, {useEffect} from 'react';
import './ProfilePage.css'
import {changePassword, getProfile, updateProfile, uploadProfilePhoto} from "src/services/apiServices";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {SET_PROFILE} from "src/store/common/action";

function ProfilePage({setProfile, profilePlaceHolder}) {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [profileImage, setProfileImage] = React.useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState('');

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileImage(file);
            setImagePreviewUrl(URL.createObjectURL(file));
        }
    };

    // Handle image upload to the API
    const handleImageUpload =  (e) => {
        e.preventDefault()
        if (!profileImage) {
            toast.error("No image selected");
            return;
        }
        const formData = new FormData();
        formData.append('profile_photo', profileImage);

        uploadProfilePhoto(formData).then(res => {
            let profilePhoto = res.data.data.profile_photo
            setImagePreviewUrl(profilePhoto);
            setProfile({
                ...profilePlaceHolder,
                profile_photo: profilePhoto,
            })
            toast.success("Photo successfully uploaded");
        })
    };

    const handleEditAccount = () => {
        const data = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone_number": phone,
        }

        updateProfile(data).then(res => {
            setProfile({
                ...profilePlaceHolder,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone_number: phone,
            });
            toast.success('Account successfully updated');
        }).catch(error => {
            toast.error('Failed to update account');
        })
    }

    const handleChangePassword = () => {
        const data = {
            current_password: password,
            new_password: newPassword
        }

        changePassword(data).then(res => {
            toast.success('Password changed successfully');
            setPassword('')
            setConfirmPassword('')
            setNewPassword('')
        }).catch(error => {
            toast.error(error.response.data.errors[0]);
        })
    }

    useEffect(() => {
        setFirstName(profilePlaceHolder.first_name)
        setLastName(profilePlaceHolder.last_name)
        setEmail(profilePlaceHolder.email)
        setPhone(profilePlaceHolder.phone_number)
        setImagePreviewUrl(`${process.env.REACT_APP_API_URL}${profilePlaceHolder.profile_photo}`)
    }, [profilePlaceHolder])

    return (
        <div className={"profile-page"}>
            <h1>Profile</h1>
            <div className={"profile-content"}>
                <div className={"account-settings"}>
                    <div>
                        <h3>Account Settings</h3>
                        <form className={"account-settings-form"}>
                            <p>First Name</p>
                            <input type="text" placeholder={"First Name"} value={firstName}
                                   onChange={e => setFirstName(e.target.value)} required/>
                            <p>Last Name</p>
                            <input type="text" placeholder={"Last Name"} value={lastName}
                                   onChange={e => setLastName(e.target.value)} required/>
                            <p>Email</p>
                            <input type={"email"} placeholder={"Email"} value={email}
                                   onChange={e => setEmail(e.target.value)} required/>
                            <p>Phone Number</p>
                            <input type={"number"} placeholder={"Phone number"} value={phone}
                                   onChange={e => setPhone(e.target.value)} required/>
                        </form>
                    </div>
                    <button type="submit" className={"submit-btn"} onClick={handleEditAccount}>Submit</button>
                </div>
                <div className={"password-settings"}>
                    <div>
                        <h3>Change Password</h3>
                        <form className={"account-settings-form"}>
                            <p>Current Password</p>
                            <input type="password" placeholder={"Current Password"} value={password}
                                   onChange={e => setPassword(e.target.value)} required/>
                            <p>New Password</p>
                            <input type={"password"} placeholder={"New Password"} value={newPassword}
                                   onChange={e => setNewPassword(e.target.value)} required/>
                            <p>Confirm Password</p>
                            <input type={"password"} placeholder={"Confirm New Password"} value={confirmPassword}
                                   onChange={e => setConfirmPassword(e.target.value)} required/>
                        </form>
                    </div>
                    <button type="submit" className={"submit-btn"} onClick={handleChangePassword}>Submit</button>
                </div>
            </div>
            <div className="profile-picture-container" style={{display: 'flex', alignItems: 'center'}}>
                <div>
                    <img
                        src={imagePreviewUrl}
                        alt="Profile"
                        style={{width: '100px', height: '100px', borderRadius: '8px', objectFit: 'cover'}}
                    />
                </div>
                <div style={{marginLeft: '20px'}}>
                    <p>Image size should be under 5MB</p>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{display: 'none'}}
                        id="file-input"
                    />
                    <label htmlFor="file-input" style={{color: 'orange', cursor: 'pointer'}}>
                        Change photo
                    </label>
                    {profileImage && (
                        <button onClick={handleImageUpload} style={{marginLeft: '10px'}}>
                            Upload
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProfile: payload => dispatch({type: SET_PROFILE, payload: payload})
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        profilePlaceHolder: state.common.profilePlaceHolder,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);