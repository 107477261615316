import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import TicketingPage from './pages/TicketingPage/TicketingPage';
import DealRegistrationPage from './pages/DealRegistrationPage/DealRegistrationPage';
// import OpportunityPage from './pages/OpportunityPage';
// import UniversityPage from './pages/UniversityPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Sidebar from './components/Sidebar/Sidebar';
import './App.css';
import Header from "./components/Header/Header";
import ProtectedRoute from "./components/ProtectedRoute";
import {Bounce, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import UploadContentPage from "src/pages/UploadContentPage/UploadContentPage";
import UniversityPage from "src/pages/UniversityPage/UniversityPage";
import ClassPage from "src/pages/ClassPage/ClassPage";
import UploadContentForm from "src/components/UploadContentForm/UploadContentForm";
import {Provider} from "react-redux";
import store from "src/store";
import NewDealRegistration from "src/pages/DealRegistrationPage/NewDealRegistration";
import NewTicketPage from "src/pages/TicketingPage/NewTicketPage";


function App() {
    return (
        <Provider store={store}>
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route element={<ProtectedRoute />}>
                    <Route
                        path="*"
                        element={
                            <div className="app">
                                <Sidebar />
                                <div className="main-content">
                                    <Header />
                                    <Routes>
                                        <Route path="/dashboard" element={<DashboardPage />} />
                                        <Route path="/deal-registration" element={<DealRegistrationPage />} />
                                        <Route path="/deal-registration/new" element={<NewDealRegistration />} />
                                        <Route path="/ticketing" element={<TicketingPage />} />
                                        <Route path="/ticketing/new" element={<NewTicketPage />} />
                                        <Route path="/profile" element={<ProfilePage />} />
                                        <Route path="/content-management" element={<UploadContentPage />} />
                                        <Route path="/content-management/:type" element={<UploadContentForm />}/>
                                        <Route path="/content-management/:type/:classId" element={<UploadContentForm />}/>
                                        <Route path="/university" element={<UniversityPage />} />
                                        <Route path="/university/:classId" element={<ClassPage />} />
                                    </Routes>
                                </div>
                            </div>
                        }
                    />
                </Route>
            </Routes>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
                containerId="GlobalApplicationToast"
            />
            <ToastContainer />
        </Router>
        </Provider>
    );
}

export default App;
