import {Modal} from "@mui/material";
import './ModalDeleteConfirm.css'
import {deleteClass, deleteCourse} from "src/services/apiServices";
import {toast} from "react-toastify";

const ModelDeleteConfirm = (props) => {
    const handleDelete = () => {
        props.onConfirmDelete();
    };

    return (
        <div className={`modal-overlay ${props.isOpen ? "open" : ""}`} onClick={props.closeModal}>
            <div className={`modal-content-delete ${props.isOpen ? "show" : ""}`}>
                <span>Are you sure want to delete this {props.type}?</span>
                <div className={'container-button'}>
                    <button onClick={handleDelete}>Yes</button>
                    <button onClick={props.closeModal}>No</button>
                </div>
            </div>
        </div>
    )
}

export default ModelDeleteConfirm;